<template>
  <div class="carInfo">
    <el-card class="box-card father-card"
             shadow="never">
      <!-- 头部区域 -->
      <el-row class="btns">
        <el-form ref="form"
                 :model="form"
                 inline>
          <!-- 搜索框 -->
          <el-form-item label="车牌号码">
            <el-input v-model="form.carNum"
                      placeholder="请输入车牌号码"
                      clearable></el-input>
          </el-form-item>
          <el-form-item label="机构名称">
            <el-select v-model="form.corpId"
                       placeholder="请选择机构"
                       clearable>
              <el-option v-for="item in institutionsOptions"
                         :key="item.uuid"
                         :label="item.companyName"
                         :value="item.uuid">
              </el-option>
            </el-select>
          </el-form-item>
          <!-- 按钮区域 -->
          <el-form-item>
            <el-button type="success"
                       icon="el-icon-search"
                       @click="search()">搜索</el-button>
            <el-button v-permission="['/carInfoAdd']" type="primary"
                       icon="el-icon-plus"
                       @click="addDialogFormVisible = true">添加</el-button>
            <el-button v-permission="['/carInfoExamine']" type="warning"
                       icon="el-icon-user"
                       @click="audit()">审核</el-button>
            <el-button v-permission="['/carInfoCancelDetection']" type="danger"
                       icon="el-icon-delete"
                       @click="signalerkennungAbbrechen">取消信号检测</el-button>
          </el-form-item>
        </el-form>
      </el-row>
      <!-- 表格区域 -->
      <el-row class="tab">
        <el-table ref="multipleTable"
                  :data="tabData"
                  tooltip-effect="dark"
                  style="width: 100%"
                  highlight-current-row
                  stripe
                  border
                  :header-cell-style="rowClass"
                  @current-change="handleCurrentChange">
          <el-table-column prop="carNum"
                           label="车牌号码"
                           align="center"></el-table-column>
          <el-table-column prop="plateNumberColour"
                           label="车牌颜色"
                           align="center"></el-table-column>
          <el-table-column prop="ownerId"
                           label="车辆类型"
                           align="center"></el-table-column>
          <el-table-column prop="corpName"
                           label="所属机构"
                           align="center"></el-table-column>
          <el-table-column prop="ownerName"
                           label="车主名称"
                           align="center"></el-table-column>
          <!-- <el-table-column prop="carBrand"
                           label="车辆品牌"
                           align="center"></el-table-column> -->
         <el-table-column prop="createTime"
                           label="创建时间"
                           align="center"></el-table-column>
          <el-table-column prop="status"
                           label="车辆状态"
                           align="center">
            <template slot-scope="scope">
              <span effect="dark"
                    type="warning"
                    v-if="scope.row.status===0">{{ '待审核' }}</span>
              <span effect="dark"
                    type="success"
                    v-else-if="scope.row.status===1">{{ '正常' }}</span>
              <span type="danger"
                    effect="dark"
                    v-else>{{ '停用' }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="remark"
                           label="备注"
                           align="center"></el-table-column>
          <el-table-column label="操作"
                           align="center"
                           min-width="200px">
            <template slot-scope="scope">
              <el-button size="mini"
                         type="success"
                         @click="look(scope.row)">查看详情</el-button>
              <el-button v-permission="['/carInfoEdit']" size="mini"
                         type="primary"
                         icon="el-icon-edit"
                         @click="edit(scope.row)">修改</el-button>

              <!-- <el-button
                size="mini"
                type="danger"
                icon="el-icon-delete-solid"
                @click="open(scope.row)"
              >删除</el-button>-->
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination background
                       @size-change="handleSizeChange"
                       @current-change="handlePageNumChange"
                       :current-page="paging.PageNo"
                       :page-sizes="[10,15, 20, 50]"
                       :page-size="paging.pageSize"
                       layout="total, sizes, prev, pager, next, jumper"
                       :total="total"></el-pagination>
      </el-row>
      <!-- 机构详情弹出框 -->
      <el-dialog :visible.sync="orderDialogFormVisible"
                 class="order-dialog">
        <span slot="title"
              class="dialog-Title">车辆详情</span>
        <el-form :model="selectForm"
                 inline
                 ref="selectForm"
                 :rules="selectFormRules"
                 class="selectForm">
          <!-- 第一行 -->
          <el-row>
            <el-form-item label="车牌号码"
                          prop="carNum">
              <el-input v-model="selectForm.carNum"
                        autocomplete="off"
                        clearable
                        :disabled="isDisabled"></el-input>
            </el-form-item>
            <el-form-item label="车牌颜色">
              <el-input v-model="selectForm.plateNumberColour"
                        autocomplete="off"
                        clearable
                        :disabled="isDisabled"></el-input>
            </el-form-item>
            <el-form-item label="车辆类型">
              <el-input v-model="selectForm.ownerId"
                        autocomplete="off"
                        clearable
                        :disabled="isDisabled"></el-input>
            </el-form-item>
          </el-row>
          <!-- 第二行 -->
          <el-row>
            <el-form-item label="所属机构"
                          prop="corpId">
              <el-select v-model="selectForm.corpId"
                         placeholder="请选择"
                         filterable
                         clearable
                         :disabled="isDisabled">
                <el-option v-for="(item,index) in orderCompanyList"
                           :key="index"
                           :label="item.companyName"
                           :value="item.uuid"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="车主名称"
                          prop="remark">
              <el-input v-model="selectForm.ownerName"
                        autocomplete="off"
                        clearable
                        :disabled="isDisabled"></el-input>
            </el-form-item>
            <el-form-item label="车辆品牌">
              <el-input v-model="selectForm.carBrand"
                        autocomplete="off"
                        clearable
                        :disabled="isDisabled"></el-input>
            </el-form-item>
          </el-row>
          <!-- 第三行 -->
          <el-row>
            <el-form-item label="发动机号"
                          prop="ein">
              <el-input v-model="selectForm.ein"
                        autocomplete="off"
                        clearable
                        :disabled="isDisabled"></el-input>
            </el-form-item>
            <el-form-item label="生产厂家"
                          prop="manufacturer">
              <el-input v-model="selectForm.manufacturer"
                        autocomplete="off"
                        clearable
                        :disabled="isDisabled"></el-input>
            </el-form-item>
            <el-form-item label="车辆识别代号/车架号"
                          prop="vin">
              <el-input v-model="selectForm.vin"
                        autocomplete="off"
                        clearable
                        :disabled="isDisabled"></el-input>
            </el-form-item>
          </el-row>
          <!-- 第四行 -->
          <!-- 第五行 -->
          <el-row>
            <el-form-item label="车辆出厂日期"
                          prop="manufactureDate">
              <el-input v-model="selectForm.manufactureDate"
                        autocomplete="off"
                        clearable
                        :disabled="isDisabled"></el-input>
            </el-form-item>
            <el-form-item label="状态"
                          prop="status">
              <el-select v-model="selectForm.status"
                         placeholder="请选择"
                         filterable
                         :disabled="isDisabled"
                         clearable>
                <el-option v-for="(item,index) in statusList"
                           :key="index"
                           :label="item.label"
                           :value="item.status"></el-option>
              </el-select>
            </el-form-item>
          </el-row>
          <!-- 第六行 -->
          <el-row>
            <!-- <el-form-item label="状态" prop="status">
              <el-select v-model="selectForm.status" placeholder="请选择状态" filterable clearable>
                <el-option label="新增" :value="0"></el-option>
                <el-option label="正常" :value="1"></el-option>
                <el-option label="停用" :value="2"></el-option>
              </el-select>
            </el-form-item>-->
            <el-form-item label="备注"
                          prop="remark">
              <el-input v-model="selectForm.remark"
                        autocomplete="off"
                        clearable
                        :disabled="isDisabled"></el-input>
            </el-form-item>
          </el-row>
        </el-form>
        <div slot="footer"
             class="dialog-footer">
          <el-button type="primary"
                     @click="sure()">确 认</el-button>
        </div>
      </el-dialog>
      <!-- 添加弹出框 -->
      <el-dialog :visible.sync="addDialogFormVisible"
                 class="add-dialog"
                 @close="addDialogClose()">
        <span slot="title"
              class="dialog-Title">添加车辆</span>
        <el-form :model="addForm"
                 inline
                 ref="addForm"
                 class="addForm"
                 :rules="addFormRules">
          <!-- 第一行 -->
          <el-row>
            <el-form-item label="车牌号码"
                          prop="carNum">
              <el-input @blur="carNumBlur"
                        v-model="addForm.carNum"
                        autocomplete="off"
                        clearable></el-input>
            </el-form-item>
            <el-form-item label="车牌颜色">
              <el-input v-model="addForm.plateNumberColour"
                        autocomplete="off"
                        clearable></el-input>
            </el-form-item>
            <el-form-item label="车辆类型">
              <el-input v-model="addForm.ownerId"
                        autocomplete="off"
                        clearable></el-input>
            </el-form-item>
          </el-row>
          <!-- 第二行 -->
          <el-row>
            <el-form-item label="所属机构"
                          prop="corpId">
              <el-select v-model="addForm.corpId"
                         placeholder="请选择"
                         filterable
                         clearable>
                <el-option v-for="(item,index) in orderCompanyList"
                           :key="index"
                           :label="item.companyName"
                           :value="item.uuid"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="车主名称"
                          prop="remark">
              <el-input v-model="addForm.ownerName"
                        autocomplete="off"
                        clearable></el-input>
            </el-form-item>
            <el-form-item label="车辆品牌">
              <el-input v-model="addForm.carBrand"
                        autocomplete="off"
                        clearable></el-input>
            </el-form-item>
          </el-row>
          <!-- 第三行 -->
          <el-row>
            <el-form-item label="发动机号"
                          prop="ein">
              <el-input v-model="addForm.ein"
                        autocomplete="off"
                        clearable></el-input>
            </el-form-item>
            <el-form-item label="生产厂家"
                          prop="manufacturer">
              <el-input v-model="addForm.manufacturer"
                        autocomplete="off"
                        clearable></el-input>
            </el-form-item>
            <el-form-item label="车辆识别代号/车架号"
                          prop="vin">
              <el-input v-model="addForm.vin"
                        autocomplete="off"
                        clearable></el-input>
            </el-form-item>
          </el-row>
          <!-- 第四行 -->
          <!-- 第五行 -->
          <el-row>
            <el-form-item label="车辆出厂日期"
                          prop="manufactureDate">
              <el-input v-model="addForm.manufactureDate"
                        autocomplete="off"
                        clearable></el-input>
            </el-form-item>
          </el-row>
          <!-- 第六行 -->
          <el-row>
            <!-- <el-form-item label="状态" prop="status">
              <el-select v-model="addForm.status" placeholder="请选择状态" filterable clearable>
                <el-option label="新增" :value="0"></el-option>
                <el-option label="正常" :value="1"></el-option>
                <el-option label="停用" :value="2"></el-option>
              </el-select>
            </el-form-item>-->
            <el-form-item label="备注"
                          prop="remark">
              <el-input v-model="addForm.remark"
                        autocomplete="off"
                        clearable></el-input>
            </el-form-item>
          </el-row>
        </el-form>
        <div slot="footer"
             class="dialog-footer">
          <el-button v-show="!isCarNum"
                     type="info">确 认</el-button>
          <el-button v-show="isCarNum"
                     type="primary"
                     @click="addSure()">确 认</el-button>
        </div>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import _ from 'lodash'
import store from '@/store/index.js'

export default {
  name: 'carInfo',
  components: {
  },
  data () {
    return {
      tabData: [], // 表格数据源
      form: { carNum: '', corpId: '' }, // 搜索表单
      total: 0, // 总数据条数
      paging: {
        pageSize: 10, // 每页显示数据条数
        PageNo: 1 // 当前页数
      }, // 分页表单
      orderDialogFormVisible: false, // 控制详情对话框的显示与隐藏
      addDialogFormVisible: false, // 控制添加对话框的显示与隐藏
      selectForm: {}, // 选中的表单对象
      addForm: {}, // 添加表单
      // is: true,
      addFormRules: {
        companyName: [
          { required: true, message: '请输入机构名称', trigger: 'blur' }
        ],
        carNum: [
          { required: true, message: '请输入车牌号码', trigger: 'blur' }
        ],
        corpId: [
          { required: true, message: '请选择所属机构', trigger: 'blur' }
        ]
      }, // 添加表单校验规则
      selectFormRules: {
        carNum: [
          { required: true, message: '请输入车牌号码', trigger: 'blur' }
        ],
        corpId: [
          { required: true, message: '请选择所属机构', trigger: 'blur' }
        ]
      },
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      fileList: [],
      isDisabled: true, // 控制对话框的编辑
      statusList: [{ label: '正常', status: 1 }, { label: '停用', status: 2 }],
      orderCompanyList: [], // 机构列表
      select: {},
      isCarNum: true,
      institutionsOptions: []// 机构列表
    }
  },
  created () {
    this.getAllCar()
    this.getorderCompany()
    this.getInstitutions()
  },
  methods: {
    // 获取机构信息
    async getInstitutions () {
      const {
        data: res
      } = await this.$http.post('/userManageServer/tSysCompany/selectCompanyDataAuthScope', {
        companyName: '',
        headers: {
          uuid: store.state.order_company || sessionStorage.getItem('order_company'),
          AUTH_TYPE: 'company'
        }
      })
      console.log(res)
      if (res.statusCode !== '200') return this.$message.error('获取机构列表失败')
      this.institutionsOptions = res.data
    },
    /**
     * 车牌号输入框失焦请求接口查询是否存在这个车牌
     */
    async carNumBlur () {
      if (this.addForm.carNum && this.addForm.carNum != ' ') {
        const res = await this.$http.post('/pmService/tInfoVehicle/selectByCarNum', {
          carNum: this.addForm.carNum
        })
        if (res) {
          if (res.data.data) {
            this.isCarNum = false
            return this.$message.warning('车牌号已存在，请重新输入')
          } else {
            this.isCarNum = true
          }
        }
      }
    },
    // 给表格头部设定背景色/vehicleServer/tInfoVehicle/selecteTInfoVehicleService
    rowClass ({ row, rowIndex }) {
      return 'background:#e4eaec'
    },
    // 获取所有车辆列表
    async getAllCar () {
      const { data: res } = await this.$http.post('/pmService/tInfoVehicle/selecteTInfoVehicleService', {
        pageSize: this.paging.pageSize,
        pageNo: this.paging.PageNo,
        carNum: this.form.carNum,
        corpId: this.form.corpId
        // "status": 1
      })
      console.log(res)
      this.select.carNum = ''
      if (res.statusCode !== '200') return this.$message.error('获取车主列表失败')
      this.tabData = res.data
      this.total = +res.pageBean.pageDataCount
    },
    // 切换表格选中项时触发
    handleCurrentChange (v) {
      if (v) {
        this.select = _.cloneDeep(v)
      }
    },
    // 点击搜索按钮触发
    async search () {
      this.paging.PageNo = 1
      this.getAllCar()
      // 判断有输入账号
    //   if (this.form.carNum === '') {
    //     this.getAllCar()
    //   } else {
    //     // 调接口，进行查询
    //     this.paging.PageNo = 1
    //     const { data: res } = await this.$http.post('/vehicleServer/tInfoVehicle/selecteTInfoVehicleService', {
    //       pageSize: this.paging.pageSize,
    //       pageNo: this.paging.PageNo,
    //       carNum: this.form.carNum,
    //       corpId: this.form.corpId
    //     })
    //     // 判断请求是否成功
    //     if (res.statusCode !== '200') return this.$message.error('查询失败')
    //     this.tabData = res.data
    //     this.total = +res.pageBean.pageDataCount
    //   }
    },
    // 切换每页显示数量时触发
    handleSizeChange (newSize) {
      this.paging.pageSize = newSize
      this.getAllCar()
    },
    // 切换页码时触发
    handlePageNumChange (PageNum) {
      this.paging.PageNo = PageNum
      this.getAllCar()
    },
    // 点击查看详情按钮触发
    look (v) {
      this.isDisabled = true
      this.selectForm = v
      this.orderDialogFormVisible = true
    },
    // 点击编辑按钮触发
    edit (v) {
      this.isDisabled = false
      this.selectForm = _.cloneDeep(v)
      this.orderDialogFormVisible = true
    },
    // 点击删除按钮触发
    open (e) {
      this.$confirm(`是否确认删除${e.carNum}`, '删除车辆', {
        confirmButtonText: '确定删除',
        cancelButtonText: '取消删除',
        type: 'warning'
      }).then(async () => {
        try {
          const { data: res } = await this.$http.post('/vehicleServer/tInfoVehicle/deleteTInfoVehicleService', JSON.parse(JSON.stringify(e)))
          // 判断是否删除成功
          if (res.statusCode !== '200') return this.$message.error('删除失败')
          // 成功提示
          this.$message.success('删除成功')
          // 刷新视图

          this.getAllCar()
        } catch (e) {
          console.log(e)
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 点击详情确定框触发
    sure () {
      // 判断是出于查看详情还是编辑
      if (this.isDisabled) { return this.orderDialogFormVisible = false } else {
        this.$refs.selectForm.validate(async valid => {
          if (!valid) return
          // 进行编辑操作
          const { data: res } = await this.$http.post('/pmService/tInfoVehicle/updateTInfoVehicleService', JSON.parse(JSON.stringify(this.selectForm)))
          // 判断请求是否成功
          if (res.statusCode !== '200') return this.$message.error('修改失败')
          // 成功提示
          this.$message.success('修改成功')
          // 重新渲染数据
          this.getAllCar()
          this.orderDialogFormVisible = false
        })
      }
    },
    // 添加机构--确定按钮
    addSure () {
      // console.log(JSON.parse(JSON.stringify(this.addForm)));
      // 预校验
      this.$refs.addForm.validate(async valid => {
        if (!valid) return
        console.log(JSON.parse(JSON.stringify(this.addForm)))
        this.addForm.status = '0'
        // 调接口，发送添加客户请求
        const { data: res } = await this.$http.post('/pmService/tInfoVehicle/addTInfoVehicle', JSON.parse(JSON.stringify(this.addForm)))
        console.log(res)
        // 判断请求是否成功
        if (res.statusCode !== '200') return this.$message.error('添加失败')
        // 成功提示
        this.$message.success('添加成功')
        // 重新渲染数据
        this.getAllCar()
        // 关闭弹框
        this.addDialogFormVisible = false
      })
    },
    // 监听添加用户对话框的关闭事件
    addDialogClose () {
      this.$refs.addForm.resetFields()
      this.isCarNum = true
    },
    // 按地区获取机构--调试完成
    async getorderCompany (e) {
      const { data: res } = await this.$http.post('/userManageServer/tSysCompany/selectCompany', {
        status: 1
        // "cityId": 177,
        // "companyName": "岳麓"
      })
      // 判断请求是否成功
      if (res.statusCode !== '200') return this.$message.error('获取机构失败')
      this.orderCompanyList = res.data
      console.log(res)
    },
    // 点击审核按钮触发
    async audit () {
      if (!this.select.carNum) return this.$message.info('请先选中需要审核的车辆')
      if (this.select.status === 1) return this.$message.info('车辆已审核')
      // console.log(this.select);
      const { data: res } = await this.$http.post('/pmService/tInfoVehicle/updateCarStatusAuditing', {
        carNum: this.select.carNum,
        uuid: this.select.uuid
      })
      console.log(res)
      // 判断请求是否成功
      if (res.statusCode !== '200') return this.$message.error('审核失败')
      // 成功提示
      this.$message.success('审核成功')
      // 重新渲染数据
      this.getAllCar()
    },
    // 取消信号检测
    signalerkennungAbbrechen () {
      if (!this.select.carNum) return this.$message.info('请先选中需要审核的车辆')
      this.$confirm('确认取消信号检测?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const { data: res } = await this.$http.post('/pmService//tInfoVehicleDevice/cancelVehicleDeviceCheck', {
          carNum: this.select.carNum
        })
        if (res.statusCode == '200') return this.$message.success(res.resultDesc)
      })
    }

  }
}
</script>
<style lang="less" scoped>
.carInfo {
  width: 100%;
  height: 100%;
  //隐藏滚动条
  ::-webkit-scrollbar {
    // display: none; /* Chrome Safari */
  }
  .father-card {
    .btns {
      margin-bottom: 10px;
    }
    .tab {
      /deep/ .el-table {
        margin-bottom: 20px;
        .el-table__row--striped td {
          background-color: #e4eaec !important;
        }
        .current-row > td {
          background-color: #66b1ff !important;
        }
      }
      .el-pagination {
        margin-bottom: 0.25rem;
        margin-right: 0.25rem;
        text-align: right;
      }
    }
    .add-dialog,
    .order-dialog {
      .dialog-Title {
        color: #0097fe;
        font-size: 20px;
        font-weight: 700;
      }
      /deep/ .el-form {
        .subtitle {
          color: #0097fe;
          font-size: 14px;
          font-weight: 700;
          margin-bottom: 20px;
          padding-left: 6px;
          border-left: 5px solid #0097fe;
        }
        .el-row {
          display: flex;
          .el-form-item {
            flex: 1;
            display: flex;
            .el-form-item__content {
              flex: 1;
              .el-input {
                width: 100%;
                .el-input__inner {
                  width: 100%;
                }
              }
              .el-select {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
</style>
